import TappLogo from './Tapp-4.png';
import AppStoreLogo from './AppStore.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={TappLogo} className="App-logo" alt="logo" />
        <a href="https://apps.apple.com/lb/app/tappitt-online/id6741019838" target="_blank" rel="noopener noreferrer">
          <img src={AppStoreLogo} className="Appstore-logo" alt="App Store Logo" />
        </a>
      </header>
    </div>
  );
}

export default App;
